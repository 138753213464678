import { PageTemplate } from "../../components";
import ProtectedWrapper from "../../components/wrapper/protected-wrapper";
import { EnquiryLead } from "../../views";

const EnquiryLeadPage = () => {
  return (
    <ProtectedWrapper>
      <PageTemplate id="enguiry-lead-page" children={<EnquiryLead />} />
    </ProtectedWrapper>
  );
};

export default EnquiryLeadPage;
