import { PageTemplate } from "../../components";
import ProtectedWrapper from "../../components/wrapper/protected-wrapper";
import { Profile } from "../../views";

const ProfilePage = () => {
  return (
    <ProtectedWrapper>
      <PageTemplate id="profile-page" children={<Profile />} />
    </ProtectedWrapper>
  );
};

export default ProfilePage;
