import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAuthentication from "../hooks/useAuth.hook";
import "./css/login.css";
import { toast } from "react-toastify";

const Login = () => {
  const { userInfo } = useSelector((state: any) => state.auth);
  const navigate = useNavigate();
  const { login } = useAuthentication();
  const [email, setEmail] = useState<string>("user@gmail.com");
  const [password, setPassword] = useState<string>("password@123");
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  useEffect(() => {
    if (userInfo) {
      navigate("/dashboard");
    }
  });

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setIsSubmitted(true);
    if (!email || !password) {
      toast.error("Please fill in all required fields");
      return;
    }
    await login(email, password);
    setIsSubmitted(false);
  };

  return (
    <div className="login">
        {/* <Link to={"/enquiry-form"}>enquiry</Link> */}
        <Form className="login-form" onSubmit={onSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              className={isSubmitted && !email ? "input-error" : ""}
              type="email"
              placeholder="Enter email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Password</Form.Label>
            <Form.Control
              className={isSubmitted && !password ? "input-error" : ""}
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Group>
          <Button className="login-btn" variant="primary" type="submit">
            Login
          </Button>
        </Form>
    </div>
  );
};

export default Login;
