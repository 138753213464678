import { Link } from "react-router-dom";
import appConstants from "../../utils/app.contants";
import ASSETS from "../../assets";
import "../css/footer.css";

const Footer = () => {
  return (
    <div className="footer-div">
      <div className="footer-top-div">
        <div className="footer-left-div">
          <div className="footer-left-home">
            <Link className="footer-link" to="/">
              {appConstants.name}
              <img className="home-logo" src={ASSETS.logo} alt="Logo" />
            </Link>
          </div>
          <div className="footer-left-desc">
            <p>
              "Welcome to {appConstants.name}, your trusted source for
              top-quality used OEM automotive parts and accessories."
            </p>
          </div>
          <div className="footer-left-call">
            <p className="footer-left-call-text">Support center 24/7:</p>
            <Link
              className="footer-link call-link"
              to={`tel:${appConstants.primaryContactNum}`}
            >
              {appConstants.primaryContactNum}
            </Link>
          </div>
        </div>
        <div className="footer-centre-div">
          <div className="footer-centre-links">
          <Link className="footer-link" to="/Home2">
              Used Transmission
            </Link>
            <Link className="footer-link" to="/policies">
              Policies
            </Link>
            <Link className="footer-link" to="/about">
              About
            </Link>
            <Link className="footer-link" to="/contact">
              Contact
            </Link>
            <Link className="footer-link" to="/terms-and-conditions">
              Terms & Conditions
            </Link>
          </div>
        </div>
        <div className="footer-right-div">
          <h4 className="footer-contact-us">Contact Us</h4>
          <div className="footer-address-div">
            <p className="footer-address">
              {appConstants.addressLine1} <br />
              {appConstants.addressLine2} <br />
              {appConstants.addressLine3}
            </p>
          </div>
          <div className="footer-phone-div">
            <p className="footer-phone-text">
              Phone:
              <Link
                className="footer-link call-link"
                to={`tel:${appConstants.primaryContactNum}`}
              >
                {appConstants.primaryContactNum}
              </Link>
            </p>
          </div>
          <div className="footer-email-div">
            <p className="footer-email-text">
              Email:
              <Link
                className="footer-link email-link"
                to={`mailto:${appConstants.primaryContactEmail}`}
              >
                {appConstants.primaryContactEmail}
              </Link>
            </p>
          </div>
        </div>
      </div>
      <div className="footer-bottom-div">
        <p className="footer-copyright-text">
          Copyright © 2024. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};

export default Footer;
