import { PageTemplate } from "../../components";
import ProtectedWrapper from "../../components/wrapper/protected-wrapper";
import { Users } from "../../views";

const UsersPage = () => {
  return (
    <ProtectedWrapper>
      <PageTemplate id="users-page" children={<Users />} />
    </ProtectedWrapper>
  );
};

export default UsersPage;
