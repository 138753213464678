import ASSETS from "../assets";
import { IPartDetails } from "./app.interface";

const partsDetails: IPartDetails[] = [
  {
    title: "Engine",
    imgUrl: ASSETS.parts.engine,
    featureName1: "Engine Type",
    featureValue1: "Inline",
    featureName2: "Cylinders",
    featureValue2: "4",
    available: true
  },
  {
    title: "Transmission",
    imgUrl: ASSETS.parts.transmission,
    featureName1: "Transmission Type",
    featureValue1: "Automatic",
    featureName2: "Gears",
    featureValue2: "5",
    available: true
  },
  {
    title: "Headlamps",
    imgUrl: ASSETS.parts.headlamps,
    featureName1: "Headlamps",
    featureValue1: "Yes",
    featureName2: "Headlamp Type",
    featureValue2: "LED",
    available: true
  },
  {
    title: "ABS Pumps",
    imgUrl: ASSETS.parts.abspumps,
    featureName1: "ABS Pumps",
    featureValue1: "Yes",
    featureName2: "ABS Pump Type",
    featureValue2: "Manual",
    available: true
  },
  {
    title: "Computer",
    imgUrl: ASSETS.parts.computer,
    featureName1: "Computer",
    featureValue1: "Yes",
    featureName2: "Computer Type",
    featureValue2: "Manual",
    available: true
  },
  {
    title: "Radio",
    imgUrl: ASSETS.parts.radio,
    featureName1: "Radio",
    featureValue1: "Yes",
    featureName2: "Radio Type",
    featureValue2: "Manual",
    available: true
  },
  {
    title: "Rebuild Engine",
    imgUrl: ASSETS.parts.rebuildengine,
    featureName1: "Rebuild Engine",
    featureValue1: "Yes",
    featureName2: "Rebuild Engine Type",
    featureValue2: "Manual",
    available: true
  },
  {
    title: "Rebuild Transmission",
    imgUrl: ASSETS.parts.rebuildtransmission,
    featureName1: "Rebuild Transmission",
    featureValue1: "Yes",
    featureName2: "Rebuild Transmission Type",
    featureValue2: "Manual",
    available: true
  }
];


export default partsDetails;
