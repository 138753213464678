import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import { CgProfile } from "react-icons/cg";
import { useNavigate } from "react-router-dom";
import useAuthentication from "../../hooks/useAuth.hook";
import { useSelector } from "react-redux";
import "../css/nav-dropdown.css";

const NavDropdown = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: any) => state.auth);
  const { logout } = useAuthentication();

  const onClickLogout = async () => {
    await logout();
  };

  return (
    <div className="nav-dropdown">
      <DropdownButton
        align="end"
        id="nav-dropdown-button"
        title={<CgProfile id="nav-dropdown-icon" />}
      >
        <Dropdown.Item onClick={() => navigate("/profile")}>
          Profile
        </Dropdown.Item>
        <Dropdown.Item onClick={() => navigate("/dashboard")}>
          Dashborad
        </Dropdown.Item>
        {userInfo?.role !== "user" && (
          <Dropdown.Item onClick={() => navigate("/users")}>
            Users
          </Dropdown.Item>
        )}
        <Dropdown.Item onClick={onClickLogout}>Logout</Dropdown.Item>
      </DropdownButton>
    </div>
  );
};

export default NavDropdown;
