import "../css/home-bar4.css";

const HomeBar4 = () => {
  return (
    <div className="home-top-bar4">
      <div className="container home-top-bar4-container">
        <h1>Why Choose Us</h1>
        <p>
          <span>Extensive Inventory: </span>One of the largest inventories in
          the USA.
        </p>
        <p>
          <span>Wide Range of Parts:</span>
          We have a wide range of parts to choose from.
        </p>
        <p>
          <span>100% Quality Assurance: </span>
          We guarantee the quality of all your ordered items.
        </p>
        <p>
          <span>Long-Term Warranty: </span>
          All our pre-owned vehicle parts come with a long-term warranty.
        </p>
        <p>
          <span>Free Shipping and Damage Services: </span>
          We offer free shipping and damage services throughout the USA.
        </p>
        <p>
          <span>Time and Money Savings: </span>
          Ordering from us saves you both time and money.
        </p>
        <p>
          <span>Affordable Prices: </span>
          Our products are priced reasonably for your convenience.
        </p>
        <p>
          <span>Fast Delivery: </span>
          We deliver your order to your doorstep quickly.
        </p>
        <p>
          <span>24/7 Customer Support: </span>
          Our dedicated customer service team is available around the clock to
          answer all your questions.
        </p>
        <p>
          <span>Certified Engines: </span>
          All our engines are certified.
        </p>
      </div>
    </div>
  );
};

export default HomeBar4;
