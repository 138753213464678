import { PageTemplate } from "../../components";
import ProtectedWrapper from "../../components/wrapper/protected-wrapper";
import { Dashboard } from "../../views";

const DashboardPage = () => {
  return (
    <ProtectedWrapper>
      <PageTemplate id="dashboard-page" children={<Dashboard />} />
    </ProtectedWrapper>
  );
};

export default DashboardPage;
