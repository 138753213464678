import logo from "../assets/images/logo.png";
import about from "../assets/images/about.jpg";
import warranty from "../assets/images/warranty.png";
import reviews from "../assets/images/reviews.png";
import guarantee from "../assets/images/guarantee.png";
import home2herobanner from "../assets/images/home2car.png";
import engine from "../assets/images/parts/engine.png";
import transmission from "../assets/images/parts/transmission.png";
import headlamps from "../assets/images/parts/headlamps.png";
import abspumps from "../assets/images/parts/abspumps.png";
import computer from "../assets/images/parts/computer.png";
import radio from "../assets/images/parts/radio.png";
import rebuildengine from "../assets/images/parts/rebuildengine.png";
import rebuildtransmission from "../assets/images/parts/rebuildtransmission.png";
import Partp from "../assets/images/part.png";
import UserP from "../assets/images/user.png";
import Freedeliveryp from "../assets/images/free-delivery.png";
import USAp from "../assets/images/USA.png";

const ASSETS = {
  logo,
  about,
  warranty,
  reviews,
  guarantee,
  home2herobanner,
  Partp,
  UserP,
  Freedeliveryp,
  USAp,
  parts: {
    engine,
    transmission,
    headlamps,
    abspumps,
    computer,
    radio,
    rebuildengine,
    rebuildtransmission
  }
};

export default ASSETS;
