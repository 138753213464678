import { IoMdContacts } from "react-icons/io";
import { CgProfile } from "react-icons/cg";
import { RiDashboardHorizontalLine } from "react-icons/ri";
import { TbLogout } from "react-icons/tb";
import { useNavigate } from "react-router-dom";
import useAuthentication from "../../hooks/useAuth.hook";
import "../css/side-nav.css";
import { useSelector } from "react-redux";

const SideNav = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: any) => state.auth);
  const { logout } = useAuthentication();

  return (
    <div className="side-nav">
      <div className="side-nav-links side-nav-profile">
        <div
          className="side-nav-btn-wrapper"
          onClick={() => navigate("/profile")}
        >
          <p className="side-nav-p">Profile</p>
          <CgProfile className="side-nav-icons" />
        </div>
      </div>
      <div className="side-nav-links side-nav-dashboard">
        <div
          className="side-nav-btn-wrapper"
          onClick={() => navigate("/dashboard")}
        >
          <p className="side-nav-p">Dashboard</p>
          <RiDashboardHorizontalLine className="side-nav-icons" />
        </div>
      </div>
      {userInfo?.role !== "user" && (
        <div className="side-nav-links side-nav-users">
          <div
            className="side-nav-btn-wrapper"
            onClick={() => navigate("/users")}
          >
            <p className="side-nav-p">Users</p>
            <IoMdContacts className="side-nav-icons" />
          </div>
        </div>
      )}
      <div className="side-nav-links side-nav-logout">
        <div className="side-nav-btn-wrapper" onClick={logout}>
          <p className="side-nav-p">Logout</p>
          <TbLogout className="side-nav-icons" />
        </div>
      </div>
    </div>
  );
};

export default SideNav;
