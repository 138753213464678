import { FindCarPartsForm, HomeContactDiv } from "..";
import appConstants from "../../utils/app.contants";
import ASSETS from "../../assets";
import "../css/home-bar1.css";

const HomeBar1 = () => {
  return (
    <div className="home-top-bar1">
      <div className="home-left-side">
        <div className="home-pre-top-bar">
          <HomeContactDiv />
        </div>
        <FindCarPartsForm />
      </div>
      <div className="home-right-side">
        <div className="home-right-top">
          <HomeContactDiv />
        </div>
        <div className="home-right-bottom">
          <h1 className="home-right-title">
            {appConstants.name2} BRINGS EXCLUSIVE USED PARTS FOR YOUR CAR
          </h1>
          <p className="home-right-description">
            Unleash Peak Performance and Efficiency with The Affordable Used Car
            Parts.
          </p>
          <div className="home-top-bar1-imgs">
            <img
              className="home-right-warranty-img"
              src={ASSETS.warranty}
              alt="about"
            />
            <img
              className="home-right-reviews-img"
              src={ASSETS.reviews}
              alt="about"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeBar1;
