import { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import {
  addMakeApi,
  addModelApi,
  addPartApi,
  addServiceApi,
  addYearApi,
  getMakesApi,
  removeMakeApi,
  removeModelApi,
  removePartApi,
  removeServiceApi,
  removeYearApi,
} from "../../api/parts";
import { toast } from "react-toastify";
import useAppLoading from "../../hooks/useLoading.hook";
import { useSelector } from "react-redux";
import "../css/parts-form.css";

const PartsForm = () => {
  const { userInfo } = useSelector((state: any) => state.auth);
  const { setIsAppLoading } = useAppLoading();
  const [makes, setMakes] = useState([]);
  const [submits, setSubmits] = useState({
    isYearSubmitted: false,
    isMakeSubmitted: false,
    isModelSubmitted: false,
    isPartSubmitted: false,
    isServiceSubmitted: false,
  });
  const [part, setPart] = useState({
    year: "",
    make: "",
    modelMake: "",
    model: "",
    part: "",
    service: "",
  });

  const addYear = async () => {
    setSubmits({ ...submits, isYearSubmitted: true });
    if (!part.year) {
      toast.error("Please fill in the year correctly");
      return;
    }
    if (!/^\d{4}$/.test(part.year)) {
      toast.error("Year must be a 4-digit number");
      return;
    }
    try {
      setIsAppLoading(true);
      await addYearApi(part.year);
      setSubmits({ ...submits, isYearSubmitted: false });
      setPart({ ...part, year: "" });
      toast.success("Year added successfully");
    } catch (error: any) {
      console.log(error.response?.data || "Failed to create year");
      toast.error(error.response?.data || "Failed to create year");
    } finally {
      setIsAppLoading(false);
    }
  };

  const removeYear = async () => {
    setSubmits({ ...submits, isYearSubmitted: true });
    if (!part.year) {
      toast.error("Please fill in the year correctly");
      return;
    }
    const userConfirmed = window.confirm(
      `Are you sure you want to delete the year ${part.year}?`
    );
    if (userConfirmed) {
      try {
        setIsAppLoading(true);
        await removeYearApi(part.year);
        setSubmits({ ...submits, isYearSubmitted: false });
        setPart({ ...part, year: "" });
        toast.success("Year deleted successfully");
      } catch (error: any) {
        console.log(error.response?.data || "Failed to delete year");
        toast.error(error.response?.data || "Failed to delete year");
      } finally {
        setIsAppLoading(false);
      }
    }
  };

  const addMake = async () => {
    setSubmits({ ...submits, isMakeSubmitted: true });
    if (!part.make) {
      toast.error("Please fill in the make correctly");
      return;
    }
    try {
      setIsAppLoading(true);
      await addMakeApi(part.make);
      setSubmits({ ...submits, isMakeSubmitted: false });
      setPart({ ...part, make: "" });
      toast.success("Make added successfully");
    } catch (error: any) {
      console.log(error.response?.data || "Failed to create make");
      toast.error(error.response?.data || "Failed to create make");
    } finally {
      setIsAppLoading(false);
    }
  };

  const removeMake = async () => {
    setSubmits({ ...submits, isMakeSubmitted: true });
    if (!part.make) {
      toast.error("Please fill in the make correctly");
      return;
    }
    const userConfirmed = window.confirm(
      `Are you sure you want to delete the make/brand ${part.make}?`
    );
    if (userConfirmed) {
      try {
        setIsAppLoading(true);
        await removeMakeApi(part.make);
        setSubmits({ ...submits, isMakeSubmitted: false });
        setPart({ ...part, make: "" });
        toast.success("Make deleted successfully");
      } catch (error: any) {
        console.log(error.response?.data || "Failed to delete make");
        toast.error(error.response?.data || "Failed to delete make");
      } finally {
        setIsAppLoading(false);
      }
    }
  };

  const addModel = async () => {
    setSubmits({ ...submits, isModelSubmitted: true });
    if (!part.model || !part.modelMake) {
      toast.error("Please fill in the model details correctly");
      return;
    }
    try {
      setIsAppLoading(true);
      await addModelApi(part.modelMake, part.model);
      setSubmits({ ...submits, isModelSubmitted: false });
      setPart({ ...part, model: "", modelMake: "" });
      toast.success("Model added successfully");
    } catch (error: any) {
      console.log(error.response?.data || "Failed to create model");
      toast.error(error.response?.data || "Failed to create model");
    } finally {
      setIsAppLoading(false);
    }
  };

  const removeModel = async () => {
    setSubmits({ ...submits, isModelSubmitted: true });
    if (!part.model || !part.modelMake) {
      toast.error("Please fill in the brand and model details correctly");
      return;
    }
    const userConfirmed = window.confirm(
      `Are you sure you want to delete the model ${part.model}?`
    );
    if (userConfirmed) {
      try {
        setIsAppLoading(true);
        await removeModelApi(part.modelMake, part.model);
        setSubmits({ ...submits, isModelSubmitted: false });
        setPart({ ...part, model: "", modelMake: "" });
        toast.success("Model deleted successfully");
      } catch (error: any) {
        console.log(error.response?.data || "Failed to delete model");
        toast.error(error.response?.data || "Failed to delete model");
      } finally {
        setIsAppLoading(false);
      }
    }
  };

  const addPart = async () => {
    setSubmits({ ...submits, isPartSubmitted: true });
    if (!part.part) {
      toast.error("Please fill in the part correctly");
      return;
    }
    try {
      setIsAppLoading(true);
      await addPartApi(part.part);
      setSubmits({ ...submits, isPartSubmitted: false });
      setPart({ ...part, part: "" });
      toast.success("Part added successfully");
    } catch (error: any) {
      console.log(error.response?.data || "Failed to create part");
      toast.error(error.response?.data || "Failed to create part");
    } finally {
      setIsAppLoading(false);
    }
  };

  const removePart = async () => {
    setSubmits({ ...submits, isPartSubmitted: true });
    if (!part.part) {
      toast.error("Please fill in the part correctly");
      return;
    }
    const userConfirmed = window.confirm(
      `Are you sure you want to delete the part ${part.part}?`
    );
    if (userConfirmed) {
      try {
        setIsAppLoading(true);
        await removePartApi(part.part);
        setSubmits({ ...submits, isPartSubmitted: false });
        setPart({ ...part, part: "" });
        toast.success("Part deleted successfully");
      } catch (error: any) {
        console.log(error.response?.data || "Failed to delete part");
        toast.error(error.response?.data || "Failed to delete part");
      } finally {
        setIsAppLoading(false);
      }
    }
  };

  const addService = async () => {
    setSubmits({ ...submits, isServiceSubmitted: true });
    if (!part.service) {
      toast.error("Please fill in the service correctly");
      return;
    }
    try {
      setIsAppLoading(true);
      await addServiceApi(part.service);
      setSubmits({ ...submits, isServiceSubmitted: false });
      setPart({ ...part, service: "" });
      toast.success("Service added successfully");
    } catch (error: any) {
      console.log(error.response?.data || "Failed to create service");
      toast.error(error.response?.data || "Failed to create service");
    } finally {
      setIsAppLoading(false);
    }
  };

  const removeService = async () => {
    setSubmits({ ...submits, isServiceSubmitted: true });
    if (!part.service) {
      toast.error("Please fill in the service correctly");
      return;
    }
    const userConfirmed = window.confirm(
      `Are you sure you want to delete the service ${part.service}?`
    );
    if (userConfirmed) {
      try {
        setIsAppLoading(true);
        await removeServiceApi(part.service);
        setSubmits({ ...submits, isServiceSubmitted: false });
        setPart({ ...part, service: "" });
        toast.success("Service deleted successfully");
      } catch (error: any) {
        console.log(error.response?.data || "Failed to delete service");
        toast.error(error.response?.data || "Failed to delete service");
      } finally {
        setIsAppLoading(false);
      }
    }
  };

  useEffect(() => {
    setIsAppLoading(true);
    getMakesApi()
      .then((res) => {
        setMakes(res.data);
      })
      .catch((err: any) => {
        console.log(err);
        toast.error(err.response?.data || "Failed to get makes");
      })
      .finally(() => {
        setIsAppLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="parts-form">
      <div className="part-form parts-year-form">
        <Form.Control
          className={`part-form-input ${
            submits.isYearSubmitted && !part.year ? "input-error" : ""
          }`}
          type="text"
          placeholder="Enter Year"
          value={part.year}
          onChange={(e) => setPart({ ...part, year: e.target.value })}
          required
        />
        <div className="part-form-btn-wrapper">
          <Button
            className="part-form-submit-btn submit-year"
            variant="primary"
            onClick={addYear}
            type="submit"
          >
            Add Year
          </Button>
          {userInfo?.role !== "user" && (
            <Button
              className="part-form-submit-btn submit-year"
              variant="danger"
              onClick={removeYear}
              type="submit"
            >
              Remove Year
            </Button>
          )}
        </div>
      </div>
      <hr />
      <div className="part-form parts-make-form">
        <Form.Control
          className={`part-form-input ${
            submits.isMakeSubmitted && !part.make ? "input-error" : ""
          }`}
          type="text"
          placeholder="Enter Make"
          value={part.make}
          onChange={(e) => setPart({ ...part, make: e.target.value })}
          required
        />
        <div className="part-form-btn-wrapper">
          <Button
            className="part-form-submit-btn submit-make"
            variant="primary"
            onClick={addMake}
            type="submit"
          >
            Add Make
          </Button>
          {userInfo?.role !== "user" && (
            <Button
              className="part-form-submit-btn submit-make"
              variant="danger"
              onClick={removeMake}
              type="submit"
            >
              Remove Make
            </Button>
          )}
        </div>
      </div>
      <hr />
      <div className="part-form parts-model-form">
        <div className="part-form-model-input-wrapper">
          <Form.Select
            className={`part-model-make-select ${
              submits.isModelSubmitted && !part.modelMake ? "input-error" : ""
            }`}
            onChange={(e) => {
              if (e.target.value === "Select Make")
                setPart({ ...part, modelMake: "" });
              else setPart({ ...part, modelMake: e.target.value });
            }}
          >
            <option key="select-model-make">Select Make</option>
            {makes.map(({ make }) => {
              return <option key={make}>{make}</option>;
            })}
          </Form.Select>
          <Form.Control
            className={`part-form-input ${
              submits.isModelSubmitted && !part.model ? "input-error" : ""
            }`}
            type="text"
            placeholder="Enter Model"
            value={part.model}
            onChange={(e) => setPart({ ...part, model: e.target.value })}
            required
          />
        </div>
        <div className="part-form-btn-wrapper">
          <Button
            className="part-form-submit-btn submit-model"
            variant="primary"
            onClick={addModel}
            type="submit"
          >
            Add Model
          </Button>
          {userInfo?.role !== "user" && (
            <Button
              className="part-form-submit-btn submit-model"
              variant="danger"
              onClick={removeModel}
              type="submit"
            >
              Remove Model
            </Button>
          )}
        </div>
      </div>
      <hr />
      <div className="part-form parts-part-form">
        <Form.Control
          className={`part-form-input ${
            submits.isPartSubmitted && !part.part ? "input-error" : ""
          }`}
          type="text"
          placeholder="Enter Part"
          value={part.part}
          onChange={(e) => setPart({ ...part, part: e.target.value })}
          required
        />
        <div className="part-form-btn-wrapper">
          <Button
            className="part-form-submit-btn submit-part"
            variant="primary"
            onClick={addPart}
            type="submit"
          >
            Add Part
          </Button>
          {userInfo?.role !== "user" && (
            <Button
              className="part-form-submit-btn submit-part"
              variant="danger"
              onClick={removePart}
              type="submit"
            >
              Remove Part
            </Button>
          )}
        </div>
      </div>
      <hr />
      <div className="part-form parts-service-form">
        <Form.Control
          className={`part-form-input ${
            submits.isServiceSubmitted && !part.service ? "input-error" : ""
          }`}
          type="text"
          placeholder="Enter Service"
          value={part.service}
          onChange={(e) => setPart({ ...part, service: e.target.value })}
          required
        />
        <div className="part-form-btn-wrapper">
          <Button
            className="part-form-submit-btn submit-service"
            variant="primary"
            onClick={addService}
            type="submit"
          >
            Add Service
          </Button>
          {userInfo?.role !== "user" && (
            <Button
              className="part-form-submit-btn submit-service"
              variant="danger"
              onClick={removeService}
              type="submit"
            >
              Remove Service
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default PartsForm;
