import ASSETS from "../../assets";
import appConstants from "../../utils/app.contants";
import "../css/home-bar2.css";

const HomeBar2 = () => {
  return (
    <div className="home-top-bar2">
      <div className="home-bar2-left">
        <h3 className="home-bar2-left-title">WELCOME TO</h3>
        <h3 className="home-bar2-left-title">{appConstants.name2}</h3>
        <p className="home-bar2-left-description">
          Welcome to {appConstants.name2}, your one-stop store for replacement
          top-notch used Engines and Transmission parts and accessories. Located in Coachella, we
          are a trustworthy name for millions of customers across the US. Our
          goal is to provide suitable and high-quality used car body parts
          without burning a hole in the pocket. Whether it's an everyday item or
          a hard-to-find part, we work hand-in-hand with our customers to
          provide OEM-used car parts. The dedication to quality and
          affordability makes us the preferred choice for premium used auto
          parts online.
        </p>
      </div>
      <div className="home-bar2-right">
        <div className="home-bar2-right-img-div">
          <img
            className="home-bar2-right-img"
            src={ASSETS.home2herobanner}
            alt="hero banner"
          />
        </div>
      </div>
    </div>
  );
};

export default HomeBar2;
