import { Button, Form } from "react-bootstrap";
import { useEffect, useState } from "react";
import useAppLoading from "../../hooks/useLoading.hook";
import { getServicessApi, postServiceEnquiryApi } from "../../api/parts";
import { toast } from "react-toastify";
import "../css/contact-form.css";
import { IFindService } from "../../utils/app.interface";
import { initialServiceData } from "../../utils/app.initialData";

const ContactForm = () => {
  const { setIsAppLoading } = useAppLoading();
  const [service, setService] = useState<IFindService>(initialServiceData);
  const [services, setServices] = useState([]);
  const [isSubbmitted, setIsSubbmitted] = useState<boolean>(false);
  const onSubmit = async () => {
    setIsSubbmitted(true);
    if (!service.name || !service.email || !service.phone || !service.service) {
      toast.error("Please fill all the fields");
      return;
    }
    try {
      setIsAppLoading(true);
      await postServiceEnquiryApi(service);
      setIsSubbmitted(false);
      setService(initialServiceData);
      toast.success("Your service request has been submitted successfully");
    } catch (error: any) {
      console.log(error);
      toast.error(error.response?.data || "Failed to post service request");
    } finally {
      setIsAppLoading(false);
    }
  };

  useEffect(() => {
    setIsAppLoading(true);
    getServicessApi()
      .then((res) => {
        setServices(res.data);
      })
      .catch((err: any) => {
        console.log(err);
        toast.error(err.response?.data || "Failed to fetch services");
      })
      .finally(() => {
        setIsAppLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="contact-form">
      <Form.Group className="mb-3">
        <Form.Control
          className={`contact-form-input contact-form-name ${
            isSubbmitted && !service.name ? "input-error" : ""
          }`}
          placeholder="Name"
          type="text"
          value={service.name}
          onChange={(e) => setService({ ...service, name: e.target.value })}
        />
        <Form.Control
          className={`contact-form-input contact-form-email ${
            isSubbmitted && !service.email ? "input-error" : ""
          }`}
          placeholder="Email"
          type="email"
          value={service.email}
          onChange={(e) => setService({ ...service, email: e.target.value })}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Control
          className={`contact-form-input contact-form-phone ${
            isSubbmitted && !service.phone ? "input-error" : ""
          }`}
          placeholder="Phone"
          type="text"
          value={service.phone}
          onChange={(e) => setService({ ...service, phone: e.target.value })}
        />
        <Form.Select
          className={`mb-3 contact-form-input contact-form-service ${
            isSubbmitted && !service.service ? "input-error" : ""
          }`}
          onChange={(e) => {
            if (e.target.value === "Select Service")
              setService({ ...service, service: "" });
            else setService({ ...service, service: e.target.value });
          }}
        >
          <option key="select-service">Select Service</option>
          {services.map(({ service }) => {
            return <option key={service}>{service}</option>;
          })}
        </Form.Select>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Control
          className="contact-form-message"
          placeholder="Message"
          value={service.comment}
          onChange={(e) => setService({ ...service, comment: e.target.value })}
          as="textarea"
          rows={3}
          max={200}
        />
      </Form.Group>
      <Button
        className="contact-form-input contact-form-submit-btn"
        variant="primary"
        onClick={onSubmit}
        type="submit"
      >
        Submit
      </Button>
    </div>
  );
};

export default ContactForm;
