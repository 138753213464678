import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { loginApi, logoutApi } from "../api/auth";
import {
  login as loginAction,
  logout as logoutAction,
} from "../redux/slices/auth";
import useAppLoading from "./useLoading.hook";
import { toast } from "react-toastify";

const useAuthentication = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setIsAppLoading } = useAppLoading();

  const loginHook = async (email: string, password: string) => {
    try {
      setIsAppLoading(true);
      const response = await loginApi(email, password);
      dispatch(loginAction(response.data));
      navigate("/dashboard");
    } catch (error: any) {
      toast.error(
        error.response?.data || "Unable to login please try again later!!"
      );
      console.log(
        error?.response?.data || "Unable to login please try again later!!"
      );
    } finally {
      setIsAppLoading(false);
    }
  };

  const logoutHook = async () => {
    try {
      setIsAppLoading(true);
      await logoutApi();
      dispatch(logoutAction());
      navigate("/login");
    } catch (error: any) {
      toast.error(
        error.response?.data || "Unable to logout please try again later!!"
      );
      console.log(
        error?.response?.data || "Unable to logout please try again later!!"
      );
    } finally {
      setIsAppLoading(false);
    }
  };

  return { logout: logoutHook, login: loginHook };
};

export default useAuthentication;
