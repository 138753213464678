import { carModels } from "../../utils/app.contants";
import "../css/home-bar5.css";

const HomeBar5 = () => {
  return (
    <div className="home-top-bar5">
      <div className="container home-top-bar5-container">
        <div className="bar5-header">
          <h1>Popular Makes</h1>
          <h3>Wide Range of Makes & Models for Sale</h3>
        </div>
        <div className="bar5-car-models">
          {carModels.map((model, index) => (
            <div className="car-model" key={index}>
              {model}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeBar5;
