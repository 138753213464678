import { PageTemplate } from "../components";
import { TermsConditions } from "../views";

const TermsConditionsPage = () => {
  return (
    <PageTemplate id="terms-conditions-page" children={<TermsConditions />} />
  );
};

export default TermsConditionsPage;
