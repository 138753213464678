import { useSelector } from "react-redux";
import { IUser } from "../../utils/app.interface";
import "../css/users-table.css";
import { toast } from "react-toastify";

interface IUsersTableProps {
  allUsers: IUser[];
  setEditMode: (isEditMode: boolean) => void;
  setIsShowAllUsers: (isShowAllUsers: boolean) => void;
  setCurrentUser: (user: IUser) => void;
}

const UsersTable = ({
  allUsers,
  setCurrentUser,
  setIsShowAllUsers,
  setEditMode,
}: IUsersTableProps) => {
  const { userInfo } = useSelector((state: any) => state.auth);

  return (
    <div className="users-table">
      <div className="users-table-headers">
        <p className="users-table-header">#</p>
        <p className="users-table-header">Email</p>
      </div>
      <hr className="users-table-hr" />
      {allUsers.map((user, i) => {
        return (
          <div key={i} className="users-table-row-div">
            <div key={i} className="users-table-row">
              <p className="users-table-col">{i + 1}</p>
              <p
                className="users-table-col user-table-email"
                onClick={() => {
                  if (userInfo.role !== "super" && user.role !== "user") {
                    toast.error(
                      "You're not authorised to view/change an admin user"
                    );
                    return;
                  }
                  setCurrentUser(user);
                  setEditMode(true);
                  setIsShowAllUsers(false);
                }}
              >
                {user.email}
              </p>
            </div>
            <hr className="users-table-hr" />
          </div>
        );
      })}
    </div>
  );
};

export default UsersTable;
