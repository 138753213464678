import { Link } from "react-router-dom";
import appConstants from "../../../utils/app.contants";
import { BiMailSend, BiSolidPhoneCall } from "react-icons/bi";
import ASSETS from "../../../assets";

const HomeContactDiv = () => {
  return (
    <div className="home-contact">
      <div className="home-logo-div">
        <p className="home-banner0-title">
          {appConstants.name2}
          <img className="banner-home-logo" src={ASSETS.logo} alt="Logo" />
        </p>
      </div>
      <div className="home-contact-div">
        <Link
          className="home-contact-link home-call-link"
          to={`tel:${appConstants.primaryContactNum}`}
        >
          <BiSolidPhoneCall className="home-contact-icon home-phone-icon" />
        </Link>
        <Link
          className="home-contact-link home-email-link"
          to={`mailto:${appConstants.primaryContactEmail}`}
        >
          <BiMailSend className="home-contact-icon home-email-icon" />
        </Link>
      </div>
    </div>
  );
};

export default HomeContactDiv;
