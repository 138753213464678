import { useEffect, useState } from "react";
import useAppLoading from "../../hooks/useLoading.hook";
import { useLocation, useNavigate } from "react-router-dom";
import { closeEnquiryApi, getEnquiryApi } from "../../api/parts";
import { toast } from "react-toastify";
import { Button } from "react-bootstrap";
import "../css/enquiry-lead.css";

const EnquiryLead = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { setIsAppLoading } = useAppLoading();
  const [enqId, setEnqId] = useState<string>("");
  const [enquiry, setEnquiry] = useState<any>({});

  const handleClose = async () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to close this enquiry?"
    );
    if (userConfirmed) {
      try {
        setIsAppLoading(true);
        await closeEnquiryApi(enqId);
        setEnquiry({});
        toast.success("Enquiry closed successfully");
        navigate("/profile");
      } catch (error: any) {
        console.log("error", error);
        toast.error(error.response?.data || "Failed to close the enquiry");
      } finally {
        setIsAppLoading(false);
      }
    }
  };

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const enqIdValue = searchParams.get("enqId") || ""; // Get the value of 'enqId' key or use an empty string if not found

    setEnqId(enqIdValue);
  }, [location.search]);

  useEffect(() => {
    (async () => {
      if (enqId) {
        try {
          setIsAppLoading(true);
          const { data } = await getEnquiryApi(enqId);
          setEnquiry(data.enquiry);
        } catch (error: any) {
          console.log("error", error);
          toast.error(error.response?.data || "Failed to fetch the enquiry");
        } finally {
          setIsAppLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqId]);

  return (
    <div className="enquiry-lead">
      <div className="enquiry-lead-details">
        <h1 className="enquiry-lead-details-title">Enquiry Details</h1>
        <p className="enquiry-lead-enquiry-detail">
          Your Name: {enquiry.name || "-"}
        </p>
        <p className="enquiry-lead-enquiry-detail">
          Your Email: {enquiry.email || "-"}
        </p>
        <p className="enquiry-lead-enquiry-detail">
          Your Phone: {enquiry.phone || "-"}
        </p>
        <p className="enquiry-lead-enquiry-detail">
          Year: {enquiry.year || "-"}
        </p>
        <p className="enquiry-lead-enquiry-detail">
          Brand: {enquiry.make || "-"}
        </p>
        <p className="enquiry-lead-enquiry-detail">
          Model: {enquiry.model || "-"}
        </p>
        <p className="enquiry-lead-enquiry-detail">
          Part: {enquiry.part || "-"}
        </p>
        <p className="enquiry-lead-enquiry-detail">
          Service: {enquiry.service || "-"}
        </p>
        <p className="enquiry-lead-enquiry-detail">
          Comment:{" "}
          {enquiry.comments ||
            "Failed to close the enquiry Failed to close the enquiryFailed to close the enquiryFailed to close the enquiry Failed to close the enquiryFailed to close the enquiryFailed to close the enquiryFailed to close the enquiryFailed to close the enquiry"}
        </p>
        <div className="enquiry-lead-close-div">
          {enquiry.id && !enquiry.closed && (
            <Button variant="danger" onClick={handleClose}>
              Close Lead
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default EnquiryLead;
