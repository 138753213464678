import api from "./index";

export const createUserApi = async (
  name: string,
  email: string,
  phone: string,
  role: string
) => {
  return await api.post(
    "/create-user",
    {
      email,
      name,
      phone,
      role,
    }
    // withCredentials is used when server & client are on different servers but in our case we are on the same server,
    // {
    //   withCredentials: true,
    // }
  );
};

export const getUsersApi = async () => {
  return await api.get("/users");
};

export const updateUserApi = async (
  name: string,
  email: string,
  phone: string,
  role?: string
) => {
  return await api.put("/update-user", {
    email,
    name,
    phone,
    role,
  });
};

export const suspendUserApi = async (email: string) => {
  return await api.put("/suspend-user", {
    email,
  });
};

export const deleteUserApi = async (email: string) => {
  return await api.put("/delete-user", {
    email,
  });
};
