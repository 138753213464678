import { IFindCarParts, IFindService } from "./app.interface";

export const initialFindCarPartsData: IFindCarParts = {
  year: "",
  make: "",
  model: "",
  part: "",
  name: "",
  email: "",
  phone: "",
  comment: "",
};

export const initialServiceData: IFindService = {
  name: "",
  email: "",
  phone: "",
  service: "",
  comment: "",
};
