import api from "./index";

export const loginApi = async (email: string, password: string) => {
  return await api.post("/login", {
    email,
    password,
  });
};

export const logoutApi = async () => {
  return await api.post("/logout", {}, { withCredentials: true });
};
