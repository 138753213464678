import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { IUser } from "../../utils/app.interface";
import { toast } from "react-toastify";
import {
  createUserApi,
  deleteUserApi,
  suspendUserApi,
  updateUserApi,
} from "../../api/users";
import { useSelector } from "react-redux";
import "../css/user-form.css";
import useAppLoading from "../../hooks/useLoading.hook";

interface IUserFormProps {
  editMode?: boolean;
  currentUser?: IUser;
  setEditMode: (isEditMode: boolean) => void;
  setCurrentUser: (user: IUser) => void;
}

const UserForm = ({
  currentUser,
  editMode,
  setCurrentUser,
  setEditMode,
}: IUserFormProps) => {
  const { userInfo } = useSelector((state: any) => state.auth);
  const { setIsAppLoading } = useAppLoading();
  const [user, setUser] = useState({
    name: currentUser?.name || "",
    email: currentUser?.email || "",
    phone: currentUser?.phone || "",
    isAdmin: currentUser?.role === "admin" || false,
  });
  const [isSubmitted, setIsSubmitted] = useState({
    create: false,
    edit: false,
    suspend: false,
    delete: false,
  });

  const onReset = () => {
    setEditMode(false);
    setCurrentUser({} as IUser);
    setIsSubmitted({
      create: false,
      edit: false,
      suspend: false,
      delete: false,
    });
    setUser({ name: "", email: "", phone: "", isAdmin: false });
  };

  const onCreate = async () => {
    setIsSubmitted({
      create: true,
      edit: false,
      suspend: false,
      delete: false,
    });
    if (!user.name || !user.email || !user.phone) {
      toast.error("Please fill in all required fields");
      return;
    }
    try {
      setIsAppLoading(true);
      await createUserApi(
        user.name,
        user.email,
        user.phone,
        user.isAdmin ? "admin" : "user"
      );
      onReset();
      toast.success("User created successfully");
    } catch (error: any) {
      toast.error(error.response?.data || "Failed to create user");
      console.log(error.response?.data || "Failed to create user");
    } finally {
      setIsAppLoading(false);
    }
  };

  const onEdit = async () => {
    setIsSubmitted({
      edit: true,
      create: false,
      suspend: false,
      delete: false,
    });
    if (!user.name || !user.email || !user.phone) {
      toast.error("Please fill in all required fields");
      return;
    }
    try {
      setIsAppLoading(true);
      await updateUserApi(
        user.name,
        user.email,
        user.phone,
        user.isAdmin ? "admin" : "user"
      );
      toast.success("User updated successfully");
    } catch (error: any) {
      toast.error(error.response?.data || "Failed to create user");
      console.log(error.response?.data || "Failed to create user");
    } finally {
      setIsAppLoading(false);
    }
  };

  const onSupend = async () => {
    setIsSubmitted({
      suspend: true,
      edit: false,
      create: false,
      delete: false,
    });
    if (!user.email) {
      toast.error("Please fill in all required fields");
      return;
    }
    try {
      setIsAppLoading(true);
      await suspendUserApi(user.email);
      toast.success("User suspended successfully");
    } catch (error: any) {
      toast.error(error.response?.data || "Failed to create user");
      console.log(error.response?.data || "Failed to create user");
    } finally {
      setIsAppLoading(false);
    }
  };

  const onDelete = async () => {
    setIsSubmitted({
      delete: true,
      edit: false,
      create: false,
      suspend: false,
    });
    if (!user.email) {
      toast.error("Please fill in all required fields");
      return;
    }
    try {
      setIsAppLoading(true);
      await deleteUserApi(user.email);
      toast.success("User deleted successfully");
    } catch (error: any) {
      toast.error(error.response?.data || "Failed to create user");
      console.log(error.response?.data || "Failed to create user");
    } finally {
      setIsAppLoading(false);
    }
  };

  return (
    <div className="user-form-div">
      <div className="user-form">
        <Form.Control
          className={`user-form-input mb-3 ${
            isSubmitted.create && !user.name ? "input-error" : ""
          }`}
          type="text"
          placeholder="Enter Name"
          value={user.name}
          onChange={(e) => setUser({ ...user, name: e.target.value })}
          required
        />
        <Form.Control
          className={`user-form-input mb-3 ${
            (isSubmitted.create && !user.email) ||
            (isSubmitted.edit && !user.email) ||
            (isSubmitted.suspend && !user.email) ||
            (isSubmitted.delete && !user.email)
              ? "input-error"
              : ""
          }`}
          type="email"
          placeholder="Enter Email"
          value={user.email}
          onChange={(e) => setUser({ ...user, email: e.target.value })}
          required
        />
        <Form.Control
          className={`user-form-input mb-3 ${
            (isSubmitted.create && !user.phone) ||
            (isSubmitted.edit && !user.phone)
              ? "input-error"
              : ""
          }`}
          type="text"
          placeholder="Enter Phone"
          value={user.phone}
          onChange={(e) => setUser({ ...user, phone: e.target.value })}
          required
        />
        {userInfo?.role === "super" && (
          <Form.Group className="user-form-radio-group mb-3">
            <p>Admin</p>
            <Form.Check
              className="user-form-radio"
              label="Yes"
              type="radio"
              checked={user.isAdmin}
              onChange={() => setUser({ ...user, isAdmin: true })}
            />
            <Form.Check
              className="user-form-radio"
              label="No"
              type="radio"
              checked={!user.isAdmin}
              onChange={() => setUser({ ...user, isAdmin: false })}
            />
          </Form.Group>
        )}

        <div className="user-form-submit-btns-div">
          <Button
            className="user-form-reset-btn user-form-submit-btn"
            variant="primary"
            onClick={onReset}
            type="submit"
          >
            Reset
          </Button>
          {editMode ? (
            <>
              <Button
                className="user-form-delete-btn user-form-submit-btn"
                variant="primary"
                onClick={onDelete}
                type="submit"
              >
                Delete User
              </Button>
              <Button
                className="user-form-suspend-btn user-form-submit-btn"
                variant="primary"
                onClick={onSupend}
                type="submit"
              >
                Suspend User
              </Button>
              <Button
                className="user-form-update-btn user-form-submit-btn"
                variant="primary"
                onClick={onEdit}
                type="submit"
              >
                Update User
              </Button>
            </>
          ) : (
            <Button
              className="user-form-create-btn user-form-submit-btn"
              variant="primary"
              onClick={onCreate}
              type="submit"
            >
              Create User
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default UserForm;
