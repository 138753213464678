import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import {
  HomePage,
  LoginPage,
  PoliciesPage,
  AboutPage,
  ContactPage,
  EnquiryPage,
  DashboardPage,
  ProfilePage,
  EnquirySuccessPage,
  EnquiryLeadPage,
  UsersPage,
  TermsConditionsPage,
} from "./pages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { Loading } from "./components";

const App = () => {
  const { loading } = useSelector((state: any) => state.appLoading);

  return (
    <BrowserRouter>
      {loading && <Loading />}
      <Routes>
        {/* public routes */}
        <Route>
          <Route path="/*" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/policies" element={<PoliciesPage />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsConditionsPage />}
          />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/enquiry-form" element={<EnquiryPage />} />
          <Route path="/enquiry-success" element={<EnquirySuccessPage />} />
        </Route>
        {/* user routes */}
        <Route>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/users" element={<UsersPage />} />
          <Route path="/lead" element={<EnquiryLeadPage />} />
        </Route>
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
};

export default App;
