const appConstants = {
  name: "Used Engines ",
  name2:"Less Used Engines",
  primaryContactNum: "+1 9123079625",
  primaryContactEmail: "Lessusedengine@gmail.com",
  addressLine1: "Less Ued Engine & Transmission LLC Services",
  addressLine2: "49615 CESAR CHAVEZ",
  addressLine3: "St Coachella CA 92236, United States",
};

export const carModels = [
  "ACURA",
  "AUDI",
  "BMW",
  "BUICK",
  "CADILLAC",
  "CHEVROLET",
  "CHRYSLER",
  "DODGE",
  "FIAT",
  "FORD",
  "GMC",
  "HONDA",
  "HUMMER",
  "HYUNDAI",
  "INFINITI",
  "ISUZU",
  "JAGUAR",
  "JEEP",
  "KIA",
  "LANDROVER",
  "LEXUS",
  "LINCOLN",
  "MAZDA",
  "MERCEDES",
  "MERCURY",
  "MINI",
  "MITSUBISHI",
  "NISSAN",
  "OLDSMOBILE",
  "PONTIAC",
  "PORSCHE",
  "SAAB",
  "SATURN",
  "SCION",
  "SMART",
  "SUBARU",
  "SUZUKI",
  "TESLA",
  "TOYOTA",
  "VOLKSWAGEN",
  "VOLVO",
]

export default appConstants;
