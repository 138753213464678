import ASSETS from "../../assets";
import "../css/home-bar6.css";

const HomeBar6 = () => {
  return (
    <div className="home-top-bar6">
<h2>Used Engines Inc.</h2>
<p >Used Engines Inc. is the largest supplier of used engines and used
  transmissions across the United States. Our company offers unparalleled access to a huge
  inventory of quality used auto parts for most of the car and truck models at an affordable
  price. All our used motors and used transmissions are as reliable as the new one & offer
  maximum performance & efficiency. We are very particular about the quality of our products.
</p>
<div className="home-top-bar6">
       <div className="exp_div"> 
        <img className="ex_div imgp"
       src={ASSETS.UserP}
       />
          <h2 className="text-black">40k</h2>
         <span>Engines Sale</span>
        </div>

        <div className="exp_div">
        <img className="ex_div imgp"
        src={ASSETS.UserP}
        />
            <h2 className="text-black">40,000+</h2>
            <span>Satisfied Customers</span>
          </div>

        <div className="exp_div">
          <img className="ex_div imgp"
        src={ASSETS.Freedeliveryp}
        />
            <h2 className="text-black">Over 40</h2>
            <span>Million Used OEM Parts</span>
        </div>

        <div className="exp_div">
          <img className="ex_div imgp"
        src={ASSETS.USAp}
        />
            <h2 className="text-black">2000+</h2>
            <span>Inventory Yards</span>
        </div>      
    </div>
    </div>
  );
};

export default HomeBar6;

        