import React, { useEffect } from "react";
import { Button, Card, Form } from "react-bootstrap";
import { IFindCarParts } from "../../utils/app.interface";
import { initialFindCarPartsData } from "../../utils/app.initialData";
import { toast } from "react-toastify";
import useAppLoading from "../../hooks/useLoading.hook";
import {
  getMakesApi,
  getModelsApi,
  getPartsApi,
  getYearsApi,
  postEnquiryApi,
} from "../../api/parts";
import { useNavigate } from "react-router-dom";
import "../css/find-car-parts.css";

const FindCarParts = () => {
  const { setIsAppLoading } = useAppLoading();
  const navigate = useNavigate();
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const [years, setYears] = React.useState([]);
  const [makes, setMakes] = React.useState([]);
  const [models, setModels] = React.useState([]);
  const [parts, setParts] = React.useState([]);
  const [part, setPart] = React.useState<IFindCarParts>(
    initialFindCarPartsData
  );

  useEffect(() => {
    (async () => {
      setIsAppLoading(true);
      try {
        const { data: yearsData } = await getYearsApi();
        setYears(yearsData);
        const { data: makesData } = await getMakesApi();
        setMakes(makesData);
        const { data: partsData } = await getPartsApi();
        setParts(partsData);
      } catch (error: any) {
        console.error(error);
        toast.error(error.response?.data || "Failed to load data");
      } finally {
        setIsAppLoading(false);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (part.make) {
      (async () => {
        setIsAppLoading(true);
        try {
          const { data: modelsData } = await getModelsApi(part.make);
          setModels(modelsData);
        } catch (error: any) {
          console.error(error);
          toast.error(error.response?.data || "Failed to load data");
        } finally {
          setIsAppLoading(false);
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [part.make]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setIsSubmitted(true);

    if (!part.year) {
      toast.error("Please select the year");
      return;
    }

    if (!part.make) {
      toast.error("Please select the make");
      return;
    }

    if (!part.model) {
      toast.error("Please select the model");
      return;
    }

    if (!part.name) {
      toast.error("Please enter your name");
      return;
    }

    if (!part.email) {
      toast.error("Please enter your email");
      return;
    }

    if (!part.phone) {
      toast.error("Please enter your phone number");
      return;
    }

    try {
      setIsAppLoading(true);
      const { data } = await postEnquiryApi(part);
      toast.success("Enquiry submitted successfully");
      setPart(initialFindCarPartsData);
      setIsSubmitted(false);
      navigate(`/enquiry-success?enqId=${data.enquiry.id}`);
    } catch (error: any) {
      toast.error(
        error?.response?.data ||
          "Unable to make request please try again later!!"
      );
      console.log("error");
      console.log(
        error?.response?.data ||
          "Unable to make request please try again later!!"
      );
    } finally {
      setIsAppLoading(false);
    }
  };

  return (
    <div className="find-car-parts-form">
      <Form>
        <Form.Group className="mb-3 text-center">
          <Card.Title id="form-title">Find Your Car Parts</Card.Title>
        </Form.Group>
        <Form.Select
          aria-label="select-year"
          className={`mb-3 ${isSubmitted && !part.year ? "input-error" : ""}`}
          onChange={(e) => {
            if (e.target.value === "Select Year")
              setPart({ ...part, year: "" });
            else setPart({ ...part, year: e.target.value });
          }}
        >
          <option key="select-year">Select Year</option>
          {years.map(({ year }) => {
            return <option key={year}>{year}</option>;
          })}
        </Form.Select>
        <Form.Select
          aria-label="select-make"
          className={`mb-3 ${isSubmitted && !part.make ? "input-error" : ""}`}
          onChange={(e) => {
            if (e.target.value === "Select Make")
              setPart({ ...part, make: "", model: "" });
            else setPart({ ...part, make: e.target.value, model: "" });
          }}
        >
          <option key="select-make">Select Make</option>
          {makes.map(({ make }) => {
            return <option key={make}>{make}</option>;
          })}
        </Form.Select>
        <Form.Select
          aria-label="select-model"
          className={`mb-3 ${isSubmitted && !part.model ? "input-error" : ""}`}
          onChange={(e) => {
            if (e.target.value === "Select Model")
              setPart({ ...part, model: "" });
            else setPart({ ...part, model: e.target.value });
          }}
        >
          <option key="select-model">Select Model</option>
          {models.map(({ model }) => {
            return <option key={model}>{model}</option>;
          })}
        </Form.Select>
        <Form.Select
          aria-label="select-part"
          className="mb-3"
          onChange={(e) => {
            if (e.target.value === "Select Part")
              setPart({ ...part, part: "" });
            else setPart({ ...part, part: e.target.value });
          }}
        >
          <option key="select-part">Select Part</option>
          {parts.map(({ part }) => {
            return <option key={part}>{part}</option>;
          })}
        </Form.Select>
        <Form.Group className="mb-3">
          <Form.Control
            className={`${isSubmitted && !part.name ? "input-error" : ""}`}
            type="text"
            placeholder="Enter name"
            value={part.name}
            onChange={(e) => setPart({ ...part, name: e.target.value })}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            className={`${isSubmitted && !part.email ? "input-error" : ""}`}
            type="email"
            placeholder="Enter email"
            value={part.email}
            onChange={(e) => setPart({ ...part, email: e.target.value })}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            className={`${isSubmitted && !part.phone ? "input-error" : ""}`}
            type="text"
            placeholder="Enter phone"
            value={part.phone}
            onChange={(e) => setPart({ ...part, phone: e.target.value })}
            required
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Control
            as="textarea"
            rows={3}
            value={part.comment}
            placeholder="Enter comment(optional)"
            onChange={(e) => setPart({ ...part, comment: e.target.value })}
            max={200}
          />
        </Form.Group>
        <Button
          className="form-submit-btn"
          variant="primary"
          onClick={onSubmit}
          type="submit"
        >
          Submit
        </Button>
      </Form>
    </div>
  );
};

export default FindCarParts;
