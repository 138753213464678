import {
  HomeBar1,
  HomeBar2,
  HomeBar3,
  HomeBar4,
  HomeBar5,
  HomeBar6,
} from "../components";
import "./css/home.css";

const Transmission = () => {
  return (
    <div className="home">
      <HomeBar1 />
      <HomeBar2 />
      <HomeBar3 />
      <HomeBar4 />
      <HomeBar5 />
      <HomeBar6 />
    </div>
  );
};

export default Transmission;
