import { Nav } from "react-bootstrap";
import "../css/users-nav.css";

interface IUsersNavProps {
  isShowAllUsers: boolean;
  setIsShowAllUsers: (isShowAllUsers: boolean) => void;
}

const UsersNav = ({ setIsShowAllUsers, isShowAllUsers }: IUsersNavProps) => {
  return (
    <Nav fill variant="tabs">
      <Nav.Item
        className={`nav-tab ${isShowAllUsers ? "active-tab" : ""}`}
        onClick={() => setIsShowAllUsers(true)}
      >
        <p className="tab-btn">All Users</p>
      </Nav.Item>
      <Nav.Item
        className={`nav-tab ${!isShowAllUsers ? "active-tab" : ""}`}
        onClick={() => setIsShowAllUsers(false)}
      >
        <p className="tab-btn">Add User</p>
      </Nav.Item>
    </Nav>
  );
};

export default UsersNav;
