import { FaLocationDot } from "react-icons/fa6";
import { IoCallSharp } from "react-icons/io5";
import { CgMail } from "react-icons/cg";
import appConstants from "../utils/app.contants";
import { Link } from "react-router-dom";
import { ContactForm } from "../components";
import "./css/contact.css";

const Contact = () => {
  return (
    <div className="contact">
      <div className="contact-top">
        <h1 className="contact-title">Need Help!! Get in touch</h1>
      </div>
      <div className="contact-bottom">
        <div className="contact-left">
          <div className="contact-bottom-left-title">
            <h1>Get in touch</h1>
          </div>
          <div className="contact-link-div contact-address">
            <FaLocationDot className="contact-address-icon contact-icon" />
            <p className="contact-link-text contact-address-text">
              {appConstants.addressLine1} <br />
              {appConstants.addressLine2} <br />
              {appConstants.addressLine3}
            </p>
          </div>
          <div className="contact-link-div contact-call">
            <IoCallSharp className="contact-call-icon contact-icon" />
            <Link
              className="contact-link-text contact-call-link"
              to={`tel:${appConstants.primaryContactNum}`}
            >
              {appConstants.primaryContactNum}
            </Link>
          </div>
          <div className="contact-link-div contact-mail">
            <CgMail className="contact-mail-icon contact-icon" />
            <Link
              className="contact-link-text contact-mail-link"
              to={`mailto:${appConstants.primaryContactEmail}`}
            >
              {appConstants.primaryContactEmail}
            </Link>
          </div>
        </div>
        <div className="contact-right">
          <ContactForm />
        </div>
      </div>
    </div>
  );
};

export default Contact;
