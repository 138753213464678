import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { IPartDetails } from "../../utils/app.interface";
import { Link } from "react-router-dom";
import appConstants from "../../utils/app.contants";
import "../css/part-details-card.css";

const PartDetailsCard: React.FC<{ partDetails: IPartDetails }> = ({
  partDetails,
}) => {
  return (
    <Card className="part-details-card">
      <Card.Img className="part-details-card-img" variant="top" src={partDetails.imgUrl} />
      <Card.Body className="part-details-card-body">
        <div className="part-details-card-title-div">
          <Card.Title className="part-details-card-title">
            {partDetails.title}
          </Card.Title>
        </div>
        <div className="part-details-feature">
          <Card.Text>
            {partDetails.featureName1} : {partDetails.featureValue1}
          </Card.Text>
          <Card.Text>
            {partDetails.featureName2} : {partDetails.featureValue2}
          </Card.Text>
        </div>
        <Card.Text>
          In Stock: {partDetails.available ? "Available" : "Not Available"}
        </Card.Text>
        <div className="parts-details-card-btns-wrapper">
          <Link
            className="parts-details-card-link card-call-link"
            to={`tel:${appConstants.primaryContactNum}`}
          >
            <Button
              className="part-details-card-btn btn-call-now"
              variant="outline-dark"
            >
              Call Now
            </Button>
          </Link>
          <Link
            className="parts-details-card-link card-call-link"
            to={`/enquiry-form`}
          >
            <Button
              className="part-details-card-btn btn-send-inquiry"
              variant="outline-dark"
            >
              Send Inquiry
            </Button>
          </Link>
        </div>
      </Card.Body>
    </Card>
  );
};

export default PartDetailsCard;
