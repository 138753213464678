import { useEffect, useState } from "react";
import { SideNav, UserForm, UsersNav, UsersTable } from "../../components";
import { IUser } from "../../utils/app.interface";
import { getUsersApi } from "../../api/users";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useAppLoading from "../../hooks/useLoading.hook";
import "../css/users.css";

const Users = () => {
  const { userInfo } = useSelector((state: any) => state.auth);
  const { setIsAppLoading } = useAppLoading();
  const [isShowAllUsers, setIsShowAllUsers] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [currentUser, setCurrentUser] = useState({} as IUser);
  const [users, setUsers] = useState<IUser[]>([]);

  useEffect(() => {
    if (userInfo?.role) {
      setIsAppLoading(true);
      getUsersApi()
        .then((res) => {
          setUsers(res.data.users);
        })
        .catch((err) => {
          toast.error(err.response.data || "Failed to fetch users");
          console.log(err);
        })
        .finally(() => {
          setIsAppLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo?.role]);

  return (
    <div className="users">
      <SideNav />
      <div className="users-right">
        <div className="users-right-top">
          <UsersNav
            setIsShowAllUsers={setIsShowAllUsers}
            isShowAllUsers={isShowAllUsers}
          />
        </div>
        <div className="user-right-bottom">
          {isShowAllUsers ? (
            <UsersTable
              allUsers={users}
              setCurrentUser={setCurrentUser}
              setEditMode={setEditMode}
              setIsShowAllUsers={setIsShowAllUsers}
            />
          ) : (
            <UserForm
              currentUser={currentUser}
              editMode={editMode}
              setEditMode={setEditMode}
              setCurrentUser={setCurrentUser}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Users;
