import { IFindCarParts, IFindService } from "../utils/app.interface";
import api from "./index";

export const addYearApi = async (year: string) => {
  return await api.post("/add-year", {
    year,
  });
};

export const getYearsApi = async () => {
  return await api.get("/years");
};

export const removeYearApi = async (year: string) => {
  return await api.put("/delete-year", {
    year,
  });
};

export const addMakeApi = async (make: string) => {
  return await api.post("/add-make", {
    make,
  });
};

export const getMakesApi = async () => {
  return await api.get("/makes");
};

export const removeMakeApi = async (make: string) => {
  return await api.put("/delete-make", {
    make,
  });
};

export const addModelApi = async (make: string, model: string) => {
  return await api.post("/add-model", {
    make,
    model,
  });
};

export const getModelsApi = async (make: string) => {
  return await api.get(`/models/${make}`);
};

export const removeModelApi = async (make: string, model: string) => {
  return await api.put("/delete-model", {
    make,
    model,
  });
};

export const addPartApi = async (part: string) => {
  return await api.post("/add-part", {
    part,
  });
};

export const getPartsApi = async () => {
  return await api.get("/parts");
};

export const removePartApi = async (part: string) => {
  return await api.put("/delete-part", {
    part,
  });
};

export const addServiceApi = async (service: string) => {
  return await api.post("/add-service", {
    service,
  });
};

export const getServicessApi = async () => {
  return await api.get("/services");
};

export const removeServiceApi = async (service: string) => {
  return await api.put("/delete-service", {
    service,
  });
};

export const postEnquiryApi = async (part: IFindCarParts) => {
  return await api.post("/parts-enquiry/new", {
    part,
  });
};

export const postServiceEnquiryApi = async (enquiry: IFindService) => {
  return await api.post("/parts-service-enquiry/new", {
    enquiry,
  });
};

export const getEnquiryApi = async (id: string) => {
  return await api.get(`/parts-enquiry/${id}`);
};

export const getEnquiriesApi = async () => {
  return await api.get("/parts-enquiries");
};

export const closeEnquiryApi = async (id: string) => {
  return await api.put(`/parts-enquiry/close/${id}`);
};
