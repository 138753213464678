import { PageTemplate } from "../components";
import { EnquirySuccess } from "../views";

const EnquirySuccessPage = () => {
  return (
    <PageTemplate id="enguiry-success-page" children={<EnquirySuccess />} />
  );
};

export default EnquirySuccessPage;
