import appConstants from "../utils/app.contants";
import "./css/policies.css";

const Policies = () => {
  return (
    <div className="policies">
      <br />
      <div className="cancallation">
        <h4>Return & Cancellation Policy</h4>
        <p>
          {appConstants.name} is a place where you can buy the perfect used
          transmission and used engine for your vehicle. We are aware of the
          irregular nature of the situation and the possibility that you could
          need to cancel an order on occasion. Don't worry, we've got you
          covered! You have the right to cancel your order at any moment before
          obtaining our official confirmation.
        </p>
        <p>
          We want to offer rapid and effective service, so orders cannot be
          canceled once they have been verified and processed for shipping. This
          is due to the speed with which we work to ensure that you receive your
          car parts.
        </p>
        <p>
          You can process your cancellation request on weekdays that is Monday
          to Friday between 9:00 AM to 6:00 PM. By doing this, we can reduce
          wait times and ensure that your things will be despatched quickly and
          delivered on schedule. This will allow you to relax knowing that it
          will arrive whether you are ordering a part to make a quick repair or
          just stocking up.
        </p>
        <p>
          We appreciate the faith you have in us when you make a purchase, and
          we want to reassure you that we have a detailed process in place to
          address any problems you might have with your order. Our policy allows
          authorized returns to be accepted for the majority of our products
          within 90 calendar days of the purchaser receiving the item. The
          product lines from Kool Vue, Replacement, Bolton Premiere, Evan
          Fischer, Garage Pro, StyleLine, N-Dure, Auto Trust, 4WD Pros, and Item
          Auto are among those represented in this timeline.
        </p>
        <p>
          We ask that all returned items be unopened and in their original
          packaging to guarantee a seamless return procedure. This includes
          every component from the initial delivery. poorly mounted, poorly
          assembled, or damaged by user error components are not returnable.
        </p>
        <p>
          Please be informed that {appConstants.name} disclaims liability for
          any additional costs incurred as a result of the installation or use
          of flawed or improper parts. This covers any associated labor charges,
          installation fees, towing charges, further repair charges, or costs
          for automobile rentals. Verify that all parts are acceptable and in
          good condition before starting any installs.
        </p>
        <p>
          We respectfully ask that you inspect all packages as soon as you
          receive your order for any missing, damaged, or mistakenly included
          parts as part of our commitment to offering top-notch service. Please
          politely decline delivery of any broken components and make
          arrangements for the carrier to bring the item back to the sender as
          soon as feasible. Regardless of who is at fault, we sadly cannot
          accept liability for any missing, harmed, or inaccurate parts after 90
          calendar days.
        </p>
      </div>
      <br />
      <div className="warranty">
        <h4>Warranty Policy</h4>
        <p>
          Our products at {appConstants.name} are more than simply car parts;
          they stand for our unwavering commitment to excellence, dependability,
          and customer satisfaction. We are conscious of the fact that each item
          you buy from us entails a financial investment in your car that needs
          to be safeguarded and guaranteed.
        </p>
        <p>
          While dominating the car parts industry, we consistently strive to
          uphold the highest levels of customer satisfaction. This passion is
          shown in every part, accessory, or assembly that is carefully selected
          and provided from our facilities. Our quality assurance seal, which
          attests to our dedication to quality control and demonstrates how much
          we respect each and every one of our customers, is included with every
          product. Additionally, our warranty is not merely a run-down of
          legalese or a criteria checklist. It is a tangible illustration of our
          commitment to our customers and our promise to stand behind and
          support our goods. This method ensures that every time you turn the
          ignition key, the car will run smoothly and dependably without a
          single component breaking down.
        </p>
        <p>
          Each product in our inventory is meticulously tested and inspected
          before being packaged for delivery in order to uphold this assurance.
          Although strict, this meticulous method is not impervious to rare
          mistakes. Since we are aware of this, we have assembled a responsive,
          dedicated staff to assist you should the unlikely happen. The first
          line of defense is to repair any damaged components. However, if a
          replacement is not feasible for any reason, we will fully reimburse
          the full amount you were charged for your order. Additionally,
          practically all of the auto parts we provide are covered by this
          pledge, providing our customers with a high level of assurance.
        </p>
        <p>
          When it comes to the warranty policy, we are upfront. We want to be
          clear that our warranty does not include labor costs for setting up or
          taking down things. It also does not pay for additional costs like
          towing fees, rental car fees, or additional repairs needed as a result
          of utilizing subpar or incorrect components. Instead of just selling
          parts, our business wants to build relationships based on trust and
          client pleasure. The warranty policy at {appConstants.name} is a clear
          representation of this company idea. To provide our clients with the
          best service possible, we keep helping and supporting them even after
          the deal is completed.
        </p>
      </div>
      <br />
      <div className="privacy">
        <h4>Privacy Policy</h4>
        <p>
          The website https://lessusedengine.us/ (hereafter referred to as
          "Service") is run by {appConstants.name} ("us", "we," or "our"). The
          choices you have regarding the personal data we collect, use, and
          disclose when you use our service are described in this privacy
          statement (the "Policy"). We are aware of the gravity of our
          responsibility as the entrusted custodians of your personal
          information. We value our relationship with you and are grateful for
          your trust in us when you choose to share your personal information.
          We must therefore give a clear and thorough justification of our data
          collecting, usage, and security procedures.
        </p>
        <p>
          This privacy statement aims to explain the procedures we use and the
          purposes for which we collect and use your personal data (as defined
          herein). Before using our Site or disclosing personal information, you
          must carefully read and understand this document. We retain the right
          to change the terms of this privacy policy at any time. If such
          changes take place, we will let you know by posting an updated Privacy
          Policy on our website that includes the date of the most recent
          update. Your continued use of the Site after these revisions suggests
          that you accept these changes. Every time you visit our Site, we
          encourage you to periodically review our Privacy Policy to remain
          informed of any updates and to make sure you comprehend how your
          personal information will be handled. Please see the section below
          under "Changes to this Privacy Policy" for more information on the
          most current updates.
        </p>
        <p>
          We emphasize the need to carefully read this Privacy Policy. Your use
          of our Site, including any browsing or use of its services,
          constitutes your acceptance of this Privacy Policy, all modifications
          thereto, and all other terms, policies, and disclosures that are
          referenced herein. Your actual use of the site or its services serves
          as evidence of your acceptance, and we take your continuous use of the
          site to be a consistent endorsement of this privacy statement.
        </p>
        <p>
          We are adamant about the need of having direct lines of communication
          with our users. Your comments, suggestions, and questions are
          appreciated and help us improve our services. Your faith in us goes
          beyond our policy document since we are utterly dedicated to resolving
          your issues. We look forward to building a long-lasting partnership
          with you that benefits both of us while assuring you that your privacy
          will always come first. More than just a policy, it represents our
          commitment to uphold, protect, and respect your privacy across all of
          our dealings and interactions. Your privacy is a core value to us and
          the foundation upon which our services are constructed.
        </p>
      </div>
      <br />
      <br />
      <br />
    </div>
  );
};

export default Policies;
