import { setLoading } from "../redux/slices/loading";
import { useDispatch } from "react-redux";

const useAppLoading = () => {
  const dispatch = useDispatch();

  const setIsAppLoading = (value: boolean) => {
    dispatch(setLoading(value));
  };

  return { setIsAppLoading };
};

export default useAppLoading;
