import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import appConstants from "../utils/app.contants";
import { getEnquiryApi } from "../api/parts";
import { toast } from "react-toastify";
import useAppLoading from "../hooks/useLoading.hook";
import "./css/enquiry-success.css";

const EnquirySuccess = () => {
  const { setIsAppLoading } = useAppLoading();
  const location = useLocation();
  const [enqId, setEnqId] = useState<string>("");
  const [enquiry, setEnquiry] = useState<any>({});

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const enqIdValue = searchParams.get("enqId") || ""; // Get the value of 'enqId' key or use an empty string if not found

    setEnqId(enqIdValue);
  }, [location.search]);

  useEffect(() => {
    (async () => {
      if (enqId) {
        try {
          setIsAppLoading(true);
          const { data } = await getEnquiryApi(enqId);
          setEnquiry(data.enquiry);
        } catch (error: any) {
          console.log("error", error);
          toast.error(error.response?.data || "Failed to fetch the enquiry");
        } finally {
          setIsAppLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enqId]);

  return (
    <div className="enquiry-success">
      <div className="enquiry-success-wrapper">
        <h3 className="enquiry-success-title">Congratulations!!</h3>
        <h1 className="enquiry-success-subtitle">PART IS AVAILABLE🎉</h1>
        <p className="enquiry-success-description">
          Thank you for your enquiry. We will get back to you shortly. If you
          have any urgent requests, please feel free to call us.
        </p>
        <h5 className="enquiry-success-call-link-div">
          <Link
            className="enquiry-success-call-link"
            to={`tel:${appConstants.primaryContactNum}`}
          >
            Call us: {appConstants.primaryContactNum}
          </Link>
        </h5>
        <div className="enquiry-success-details">
          <h1 className="enquiry-success-details-title">Enquiry Details</h1>
          <p className="enquiry-success-enquiry-detail">
            Your Name: {enquiry.name || "-"}
          </p>
          <p className="enquiry-success-enquiry-detail">
            Your Email: {enquiry.email || "-"}
          </p>
          <p className="enquiry-success-enquiry-detail">
            Your Phone: {enquiry.phone || "-"}
          </p>
          <p className="enquiry-success-enquiry-detail">
            Year: {enquiry.year || "-"}
          </p>
          <p className="enquiry-success-enquiry-detail">
            Brand: {enquiry.make || "-"}
          </p>
          <p className="enquiry-success-enquiry-detail">
            Model: {enquiry.model || "-"}
          </p>
          <p className="enquiry-success-enquiry-detail">
            Part: {enquiry.part || "-"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default EnquirySuccess;
