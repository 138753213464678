import { useSelector } from "react-redux";
import { SideNav } from "../../components";
import { useEffect, useState } from "react";
import { getEnquiriesApi } from "../../api/parts";
import { toast } from "react-toastify";
import useAppLoading from "../../hooks/useLoading.hook";
import { Button, Card, ListGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import "../css/profile.css";

const Profile = () => {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state: any) => state.auth);
  const { setIsAppLoading } = useAppLoading();
  const [leads, setLeads] = useState([]);

  useEffect(() => {
    (async () => {
      if (userInfo) {
        try {
          setIsAppLoading(true);
          const { data } = await getEnquiriesApi();
          setLeads(data.enquiries);
        } catch (error: any) {
          console.log(error?.response?.data || "Unable to fetch data");
          toast.error(error?.response?.data || "Unable to fetch data");
        } finally {
          setIsAppLoading(false);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  return (
    <div className="profile">
      <SideNav />
      <div className="profile-right">
        <div className="profile-top">
          <h1 className="profile-title">
            Hi {userInfo?.name}!! hear are some new leads
          </h1>
        </div>
        <div className="profile-bottom leads">
          {leads.map((lead: any) => (
            <Card className="lead" key={lead._id}>
              <Card.Header className="lead-header">{lead.email}</Card.Header>
              <ListGroup className="list-group-flush">
                {lead.service ? (
                  <ListGroup.Item>Service: {lead.service}</ListGroup.Item>
                ) : (
                  <>
                    <ListGroup.Item>YEAR: {lead.year}</ListGroup.Item>
                    <ListGroup.Item>MAKE: {lead.make}</ListGroup.Item>
                    <ListGroup.Item>MODEL: {lead.model}</ListGroup.Item>
                  </>
                )}
              </ListGroup>
              <Card.Body className="lead-card-footer">
                <Button
                  variant={lead.service ? "dark" : "primary"}
                  onClick={() => {
                    navigate(`/lead?enqId=${lead._id}`);
                  }}
                >
                  View Lead
                </Button>
              </Card.Body>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Profile;
