import { PartDetailsCard } from "..";
import partsDetails from "../../utils/parts.contants";
import "../css/home-bar3.css";

const HomeBar3 = () => {
  return (
    <div className="home-top-bar3">
      <div className="container home-top-bar3-container">
        {partsDetails.map((part, i) => {
          return <PartDetailsCard key={i} partDetails={part} />;
        })}
      </div>
    </div>
  );
};

export default HomeBar3;
