import ASSETS from "../assets";
import appConstants from "../utils/app.contants";
import "./css/about.css";

const About = () => {
  return (
    <div className="about">
      <div className="about-top">
        <h1 className="about-title">About</h1>
        <p className="about-top-description-1">
          We are thrilled to have you here and are dedicated to providing
          top-quality used auto parts for all your vehicles, including cars,
          vans, and SUVs. At {appConstants.name}, we pride ourselves on offering
          exceptional value, backed by our unbeatable warranty and competitive
          pricing.
        </p>
        <p className="about-top-description-2">
          Your satisfaction is our utmost priority, and we are eager to meet all
          your auto parts needs with the highest level of service.
        </p>
      </div>
      <div className="about-bottom">
        <div className="about-bottom-left">
          <p>
            Our convenient operating hours reflect our commitment to your
            satisfaction. We are here to assist you Monday through Friday, and
            our website is available 24/7 for your convenience. With our
            extensive online catalog featuring thousands of parts, we are
            confident you'll find exactly what you need. Shop with us today, and
            let us help you keep your car running smoothly and efficiently!
          </p>
          <p>
            At Less Used Engine & Transmission, we understand that purchasing a car
            is a significant investment, and maintaining your car, truck, or SUV
            can be just as costly. For your convenience, we offer a wide range
            of auto parts available for purchase. You can count on us to meet
            all your replacement needs at fair prices. Our sales and customer
            service representatives combine their extensive training,
            experience, and knowledge with the latest innovations to locate the
            part you need as quickly as possible.
          </p>
          <p>
            Our goal is to provide the best original equipment manufacturer
            (OEM) parts to car specialty businesses and customers. We are
            committed to maintaining our market leadership in a stable,
            financially sound, and ethically responsible manner.
          </p>
        </div>
        <div className="about-bottom-right">
          <img
            className="about-bottom-right-img"
            src={ASSETS.about}
            alt="about"
          />
        </div>
      </div>
    </div>
  );
};

export default About;
