import { FindCarPartsForm } from "../components";
import ASSETS from "../assets";
import "./css/enquiry.css";

const Enquiry = () => {
  return (
    <div className="enquiry">
      <div className="enquiry-left-side">
        <FindCarPartsForm />
      </div>
      <div className="enquiry-right-side">
        <h1 className="enquiry-right-title">
          REV UP YOUR RIDE! 🚗 ORDER GENUINE CAR PARTS TODAY!
        </h1>
        <p className="enquiry-right-description">
          Unleash Peak Performance with Quality Parts at Your Fingertips
        </p>
        <div className="enquiry-imgs">
          <img
            className="enquiry-right-guarantee-img"
            src={ASSETS.guarantee}
            alt="about"
          />
        </div>
      </div>
    </div>
  );
};

export default Enquiry;
