import { PartsForm, SideNav } from "../../components";
import "../css/dashboard.css";

const Dashboard = () => {
  return (
    <div className="dashboard">
      <SideNav />
      <PartsForm />
    </div>
  );
};

export default Dashboard;
