import React, { useEffect } from "react";
import Navbar from "../navbar/navbar";
import Footer from "../footer/footer";
import "../css/page-template.css";

interface IPageTemplateProps {
  id: string;
  children: React.ReactNode;
}

const PageTemplate = ({ id, children }: IPageTemplateProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div
      id={id}
      className="page-template"
    >
      <Navbar />
      {children}
      <Footer />
    </div>
  );
};

export default PageTemplate;
