import { Link, useLocation } from "react-router-dom";
import ASSETS from "../../assets";
import appConstants from "../../utils/app.contants";
import { useState } from "react";
import { useSelector } from "react-redux";
import NavDropdown from "../dropdowns/nav-dropdown";
import "../css/navbar.css";

interface INavLinksProps {
  toggleNav?: () => void;
}

const Links = ({ toggleNav }: INavLinksProps) => {
  const { pathname } = useLocation();
  const { userInfo } = useSelector((state: any) => state.auth);

  return (
    <div className="nav-links">
      <div className="nav-links-left">
        <Link
          className={`nav-link nav-link-home ${
            pathname === "/" ? "active-nav" : ""
          }`}
          to="/"
          onClick={toggleNav}
        >
          {appConstants.name}
          <img className="home-logo" src={ASSETS.logo} alt="Logo" />
        </Link>
      </div>
      <div className="nav-links-right">
      <Link
          className={`nav-link ${pathname === "/Home2" ? "active-nav" : ""}`}
          to="/Home2"
          onClick={toggleNav}
        >
          Used Transmission
        </Link>
        <Link
          className={`nav-link ${
            pathname === "/terms-and-conditions" ? "active-nav" : ""
          }`}
          to="/terms-and-conditions"
          onClick={toggleNav}
        >
          Terms & Conditions
        </Link>
        <Link
          className={`nav-link ${pathname === "/policies" ? "active-nav" : ""}`}
          to="/policies"
          onClick={toggleNav}
        >
          Policies
        </Link>
        <Link
          className={`nav-link ${pathname === "/about" ? "active-nav" : ""}`}
          to="/about"
          onClick={toggleNav}
        >
          About
        </Link>
        <Link
          className={`nav-link ${pathname === "/contact" ? "active-nav" : ""}`}
          to="/contact"
          onClick={toggleNav}
        >
          Contact
        </Link>

        {userInfo ? (
          <NavDropdown />
        ) : (
          <Link
            className={`nav-link ${pathname === "/login" ? "active-nav" : ""}`}
            to="/login"
            onClick={toggleNav}
          >
            Login
          </Link>
        )}
      </div>
    </div>
  );
};

const Navbar = () => {
  const [toggle, setToggle] = useState(false);

  const toggleNav = () => {
    setToggle(!toggle);
  };

  return (
    <nav className="header-nav">
      <div className="wide-nav">
        <div className="wide-nav-links">
          <Links />
        </div>
      </div>
      <div className="narrow-nav">
        <nav id="nav-button" onClick={toggleNav}>
          <div className="nav-button-bar one-bar"></div>
          <div className="nav-button-bar two-bar"></div>
          <div className="nav-button-bar three-bar"></div>
        </nav>
        <div className={`${toggle ? "narrow-nav-links" : "hidden"}`}>
          <Links toggleNav={toggleNav} />
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
