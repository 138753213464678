import React from "react";
import { useSelector } from "react-redux";
import LoadingToRedirect from "../LoadingToRedirect";

interface IProtectedWrapperProps {
  children: React.ReactNode;
}

const ProtectedWrapper = ({ children }: IProtectedWrapperProps) => {
  const { userInfo } = useSelector((state: any) => state.auth);

  return <>{userInfo ? children : <LoadingToRedirect />}</>;
};

export default ProtectedWrapper;
